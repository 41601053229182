import { combineReducers } from "redux"
import auth from "./authReducer"
import chat from "./chatReducer"
import patentReducer from "./patentReducer"

export default combineReducers({
    auth,
    chat,
    patent: patentReducer,
})
