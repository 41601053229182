import React, { useEffect, useState } from 'react';
import { Divider, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/msal/authConfig";
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken } from '../redux/actions/authActions';
import moment from 'moment';

const SessionTimeout = () => {
    const [seconds, setSeconds] = useState(null);
    const [isExpiresIn, setIsExpiresIn] = useState(false);

    const { instance, accounts } = useMsal();
    const {
        auth: {
            expiresOn
        }
    } = useSelector(state => state);

    const dispatch = useDispatch();

    useEffect(() => {
        const currentDate = moment().format('YYYY/MM/DD, hh:mm:ss A');
        const subtractSeconds = moment(expiresOn).subtract(30, 'seconds').format('YYYY/MM/DD, hh:mm:ss A');
        const exipireInSeconds = moment(subtractSeconds).diff(moment(currentDate), 'seconds');
        // var a = exipireInSeconds;
        // var b = exipireInSeconds - 40
        // var c = a-b
        setSeconds(exipireInSeconds)
    }, [expiresOn])

    useEffect(() => {
        if (seconds > 0) {
            if (seconds === 30) {
                setIsExpiresIn(true);
            }
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(timer);
        }

        if (seconds === 0) {
            instance.logout({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        }
    }, [seconds]);

    const handleSession = async () => {
        try {
            // Silently acquires an access token which is then attached to a request for MS Graph data
            await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                const tokenInfo = {
                    token: response.accessToken,
                    expiresOn: moment(response.expiresOn).format('YYYY/MM/DD, hh:mm:ss A')
                }

                dispatch(refreshToken(tokenInfo))
                setIsExpiresIn(false);
            });
        } catch (error) {
            window.location.reload();
        }
    }

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <>
            {/* <div style={{ position: 'absolute', top: 80, right: 15 }}>
                Your session will expire in {seconds} seconds <br />
                Time left : {moment.utc(seconds * 1000).format("hh:mm:ss")}
            </div> */}
            <Modal
                title={<>
                    <Space size={[8, 0]} style={{ display: 'flex', alignItems: 'center' }}>
                        <ExclamationCircleFilled style={{ fontSize: 18, color: '#faad14' }} />
                        <span>Session Expire Warning</span>
                    </Space>
                    <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                </>
                }
                open={isExpiresIn}
                onOk={handleSession}
                onCancel={() => handleLogout('popup')}
                okText="Yes, Continue"
                cancelText="No, Sign me out"
                centered
                width='50%'
                maskClosable={false}
            >
                <div style={{ lineHeight: 2 }}>
                    Your session will expire in <b>{seconds}</b> seconds. <br />
                    Click on "Continue" button to extend your session. <br />
                </div>
            </Modal>
        </>
    )
}

export default SessionTimeout