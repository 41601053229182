import { PatentActionTypes } from "../actions/patentActions"
const initialState = {
    defaultPrompts: null,
    userPrompts: null,
    isProcessing: false,
    batchInProgress: null,
    uploadProgress: 0,
}
const {
    UPDATE_DEFAULT_PROMPTS,
    UPDATE_USER_PROMPTS,
    UPDATE_BATCH_STATUS,
    UPDATE_ACTIVE_BATCH,
    UPDATE_UPLOAD_PROGRESS,
    UPDATE_BATCH_IN_PROGRESS,
    UPDATE_BATCH_IN_PROGRESS_ID,
} = PatentActionTypes

export const patentReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DEFAULT_PROMPTS:
            return { ...state, defaultPrompts: action.payload }
        case UPDATE_USER_PROMPTS:
            return { ...state, userPrompts: action.payload }
        case UPDATE_BATCH_STATUS:
            return { ...state, batchStatus: action.payload }
        case UPDATE_ACTIVE_BATCH:
            return { ...state, activeBatch: action.payload }
        case UPDATE_UPLOAD_PROGRESS:
            return { ...state, uploadProgress: action.payload }
        case UPDATE_BATCH_IN_PROGRESS:
            return { ...state, batchInProgress: action.payload }
        case UPDATE_BATCH_IN_PROGRESS_ID:
            return { ...state, batchInProgressID: action.payload }

        default:
            return state
    }
}

export default patentReducer
