import axios from "axios";
import Notify from "../components/Notify";
import { store } from '../redux/store';

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_FAST_API_APP_URL,
  // timeout: 10000, // Set the timeout to 10 seconds (adjust as needed)
  headers: {
    'Content-Type': 'application/json; charset=UTF-8'
  }
});

// Add a request interceptor
axiosClient.interceptors.request.use(config => {
  const token = store.getState().auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
},
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(res => {
  if (res.data.msg)
    Notify('success', 'Success', `${res.data.msg}`)
  return res.data
},
  error => {
    // if (error.response.status === 400) {
    //   Notify('error', 'Error', `${error.response.data.detail}`)
    // }
    // else if (error.response.status === 401) {
    //   Notify('error', 'Error', `${error.response.statusText}`)
    //   window.location.reload();
    // }
    // else {
    //   Notify('error', 'Error', `Something Went Wrong. Please try again!`)
    // }
    return Promise.reject(error)
  }
)