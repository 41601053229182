import { axiosClient } from "../../config/axiosConfig"

export const Auth_TYPES = {
    USER_PROFILE: "USER_PROFILE",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    UPDATE_USER_ID: `UPDATE_USER_ID`,
}

export const setUserProfile = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Auth_TYPES.USER_PROFILE,
            payload: data,
        })
    } catch (err) {}
}

export const refreshToken = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Auth_TYPES.REFRESH_TOKEN,
            payload: data,
        })
    } catch (error) {}
}
export const getUserID = (email) => async (dispatch) => {
    try {
        const userID = await axiosClient.post("/api/chat/get_user_id/", {
            UserEmail: email,
        })
        dispatch({
            type: Auth_TYPES.UPDATE_USER_ID,
            payload: userID,
        })
    } catch (e) {}
}
