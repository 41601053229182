import { axiosClient } from "../../config/axiosConfig"
import { message } from "antd"
import { getChatHistory } from "./chatActions"

export const PatentActionTypes = {
    UPDATE_DEFAULT_PROMPTS: `UPDATE_DEFAULT_PROMPTS`,
    UPDATE_ACTIVE_BATCH: `UPDATE_ACTIVE_BATCH`,
    UPDATE_USER_PROMPTS: `UPDATE_USER_PROMPTS`,
    UPDATE_BATCH_STATUS: `UPDATE_BATCH_STATUS`,
    UPDATE_UPLOAD_PROGRESS: `UPDATE_UPLOAD_PROGRESS`,
    UPDATE_BATCH_IN_PROGRESS: `UPDATE_BATCH_IN_PROGRESS`,
    UPDATE_BATCH_IN_PROGRESS_ID: `UPDATE_BATCH_IN_PROGRESS_ID`,
}

export const getDefaultPrompts = () => async (dispatch) => {
    const res = await axiosClient.post("/api/patents/get-default-prompts")
    dispatch({
        type: PatentActionTypes.UPDATE_DEFAULT_PROMPTS,
        payload: res,
    })
}

export const updateUserPrompts = (prompts) => {
    console.log({ prompts })
    return {
        type: PatentActionTypes.UPDATE_USER_PROMPTS,
        payload: prompts,
    }
}

export const fileCheck = async (file, updateResponse) => {
    const formdata = new FormData()
    formdata.append("file", file)
    try {
        const res = await axiosClient.post(
            "/api/patents/file-check",
            formdata,
            {
                headers: { "content-type": "multipart/formdata" },
            }
        )
        updateResponse(res)
        console.log({ res })
    } catch (err) {
        updateResponse({
            accepted: false,
            message: "Unknown file format/type, please choose another file.",
        })
    }
}
const updateBatchStatus = (data) => ({
    type: PatentActionTypes.UPDATE_BATCH_STATUS,
    payload: data,
})

export const getOnePatentChat = (data) => async (dispatch) => {
    dispatch({
        type: PatentActionTypes.UPDATE_ACTIVE_BATCH,
        payload: null,
    })
    try {
        const res = await axiosClient.post("/api/patents/get-one", data)
        dispatch({
            type: PatentActionTypes.UPDATE_ACTIVE_BATCH,
            payload:
                res && res.length
                    ? {
                          result: res,
                          BatchID: data.BatchID,
                          is_processed: res.is_processed || true,
                      }
                    : {
                          ...res,
                          BatchID: data.BatchID,
                      },
        })
    } catch (err) {}
}

export const chatType = {
    active: "active",
    background: "background",
}
export const checkProgress =
    (data, type = chatType.active) =>
    async (dispatch) => {
        try {
            const res = await axiosClient.post("/api/patents/get-one", data)
            let payload = res.length
                ? {
                      result: res,
                      is_processed: res?.is_processed || true,
                      batchID: data.BatchID,
                  }
                : { ...res, BatchID: data.BatchID }
            if (type === chatType.background)
                dispatch(updateBatchStatus(payload))
            else
                dispatch({
                    type: PatentActionTypes.UPDATE_ACTIVE_BATCH,
                    payload,
                })
            return res
        } catch (err) {}
    }

export const getUserPrompts = async (data, setter) => {
    const res = await axiosClient.post("/api/patents/get-prompts", data)
    setter(res)
}

export const updateBatchInProgress = (chat) => (dispatch) => {
    dispatch({
        type: PatentActionTypes.UPDATE_BATCH_IN_PROGRESS,
        payload: chat,
    })
}
export const updateBatchInProgressID = (id) => (dispatch) => {
    dispatch({
        type: PatentActionTypes.UPDATE_BATCH_IN_PROGRESS_ID,
        payload: id,
    })
}

export const getChat = async (payload) => {
    return await axiosClient
        .post("/api/patents/get-one", payload)
        .then((res) => res)
}

export const pauseBatch = async (payload) => {
    await axiosClient
        .post("/api/patents/pause-batch", payload)
        .then((res) => message.success(res))
        .catch((err) => message.error(err.message))
}

export const resumeBatch = async (payload) => {
    await axiosClient
        .post("/api/patents/resume-batch", payload)
        .then((res) => message.success(res))
        .catch((err) => message.error(err.message))
}

export const cancelBatch = (payload) => async (dispatch) => {
    await axiosClient
        .post("/api/patents/cancel-batch", payload)
        .then((res) => {
            message.success(res)
            dispatch(getChatHistory(payload.UserID))
        })
        .catch((err) => message.error(err.message))
}
export const deleteBatch = (payload) => async (dispatch) => {
    await axiosClient
        .post("/api/patents/delete-batch", payload)
        .then((res) => {
            message.success(res)
            dispatch(getChatHistory(payload.UserID))
        })
        .catch((err) => message.error(err.message))
}
