import React from "react"
import { Alert, Button, Divider, Image, Space, Typography } from "antd"
import SignInButton from "./SignInButton"

const { Title } = Typography

const Login = () => {
    return (
        <>
            {/* <Button variant="secondary">Request Profile Information</Button>
            <h5 className="card-title">Please sign-in to see your profile information.</h5> */}

            <div className="layout">
                <div className="main">
                    <div className="topbar">
                        <div className="topbarWrapper">
                            <div className="topLeft">
                                <div className="logo">
                                    <Space>
                                        {/* <WechatOutlined style={{ fontSize: 24, color: 'red' }} /> */}

                                        {/* <Image
                                            height={25}
                                            src='https://pafeblobprodby.blob.core.windows.net/20230505t000000z86736b20dd2a46e3baa33908b177b847/3403fd8f-4aa1-49fc-b4ca-5b3b0c08b3bd/web/Assets/Images/42fe9a61-6114-4269-b365-1f6e6ce6d6ec.png?sv=2018-03-28&sr=c&sig=TtJbePfz%2BDN0NaZfcY8a0%2FGe91e08OTF6oiQiEVWFB0%3D&se=2023-05-15T04%3A00%3A00Z&sp=rl'
                                        /> */}
                                        <span style={{ color: "#fff" }}>
                                            Patent Vitual Assistant
                                        </span>
                                        {/* <span> GenAI-Chat</span> */}
                                    </Space>
                                </div>
                            </div>
                            <div className="topRight">
                                <Image
                                    height={24}
                                    src="../assets/DuPont_tm_w.svg"
                                    preview={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="layout__content">
                        <div className="layout__content-main">
                            <div
                                style={{
                                    position: "fixed",
                                    top: "50%",
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            >
                                <div style={{ lineHeight: 2 }}>
                                    {/* <Title level={2}>401 - Unauthorized</Title> */}

                                    {/* <p>You are not authorized to access the GenAI-chat application.</p> */}

                                    <p>
                                        Please click on Sign in button to enter
                                        the chat room.
                                    </p>
                                    <div style={{ margin: "auto" }}>
                                        <SignInButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
