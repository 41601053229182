import moment from "moment"
import { axiosClient } from "../../config/axiosConfig"

export const Chat_TYPES = {
    SET_ISLOADING: "SET_ISLOADING",
    GET_CHATHISTORY: "GET_CHATHISTORY",
    GET_CONVERSATION: "GET_CONVERSATION",
    SET_CONVERSATIONID: "SET_CONVERSATIONID",
    CREATE_CONVERSATION: "CREATE_CONVERSATION",
    DELETE_CONVERSATION: "DELETE_CONVERSATION",
    CLEAR_ALL_CONVERSATIONS: "CLEAR_ALL_CONVERSATIONS",
    UPDATE_TITLE: "UPDATE_TITLE",
    UPDATE_CONVERSATIONID: "UPDATE_CONVERSATIONID",
    SEND_MESSAGE: "SEND_MESSAGE",
    ADD_CONVEERSATION_HISORY: "ADD_CONVEERSATION_HISORY",
    ISDISABLE_UPLOADICON: "ISDISABLE_UPLOADICON",
    UPDATE_PAPAERCLIPICON: "UPDATE_PAPAERCLIPICON",
    REJECT_DOCUMENT: "REJECT_DOCUMENT",
    UPDATE_CONVERSATION_HISTORY: "UPDATE_CONVERSATION_HISTORY",
}

export const setIsLoading = (data) => async (dispatch) => {
    dispatch({
        type: Chat_TYPES.SET_ISLOADING,
        payload: data,
    })
}
export const updateChatHistory = (payload) => (dispatch) => {
    dispatch({
        type: Chat_TYPES.UPDATE_CONVERSATION_HISTORY,
        payload,
    })
}
export const getChatHistory = (data) => async (dispatch) => {
    try {
        let response = await axiosClient.post("api/patents/get-all", {
            UserID: data,
        })
        response.sort((a, b) => {
            // console.log(moment(a.creation_timestamp.format()))
            return b.batchID - a.batchID
        })
        dispatch({
            type: Chat_TYPES.GET_CHATHISTORY,
            payload: response,
        })
    } catch (err) {}
}

export const getConversation = (data) => async (dispatch) => {
    try {
        const response = await axiosClient.post("/api/chat/getone/", {
            UserID: data.email,
            ConvoID: data.id,
        })

        dispatch({
            type: Chat_TYPES.GET_CONVERSATION,
            payload: response,
        })
    } catch (err) {
        dispatch({
            type: Chat_TYPES.SET_ISLOADING,
            payload: false,
        })
    }
}

export const setConversationID = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.SET_CONVERSATIONID,
            payload: data,
        })
    } catch (err) {}
}

export const createConversation = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.CREATE_CONVERSATION,
            payload: { req: data, res: "" },
        })

        // Get conversationID
        const conID = await axiosClient.post("/api/chat/create/", {
            UserID: data.userID,
            FirstMessage: data.user_prompt,
        })

        // Replace conversatoinID
        await dispatch({
            type: Chat_TYPES.UPDATE_CONVERSATIONID,
            payload: { conID: conID, key: data.key },
        })

        try {
            // Get Answer by passing Conversation, Question and UserID
            const response = await axiosClient.post("/api/chat/next/", {
                ConvoID: conID,
                UserID: data.userID,
                UserPrompt: data.user_prompt,
            })

            data.status = ""

            const answer = {
                user_prompt: data.user_prompt,
                timestamp: data.timestamp,
                bot_response: response,
                bot_timestamp: moment(),
            }

            dispatch({
                type: Chat_TYPES.CREATE_CONVERSATION,
                payload: { req: data, res: answer },
            })
        } catch (err) {
            data.status = "E"
            const answer = {
                user_prompt: data.user_prompt,
                timestamp: data.timestamp,
                bot_response: "",
                bot_timestamp: moment(),
                status: "E",
            }

            dispatch({
                type: Chat_TYPES.CREATE_CONVERSATION,
                payload: {
                    req: data,
                    res: answer,
                },
            })
        }
    } catch (err) {}
}

export const deleteConversation = (data) => async (dispatch) => {
    try {
        const response = await axiosClient.post("/api/chat/archive/", {
            ConvoID: data.ConvoID,
            UserID: data.UserID,
        })

        dispatch({
            type: Chat_TYPES.DELETE_CONVERSATION,
            payload: data,
        })
    } catch (err) {}
}

export const clearAllConversations = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.CLEAR_ALL_CONVERSATIONS,
            payload: data,
        })
    } catch (err) {}
}

export const updateTitle = (data) => async (dispatch) => {
    try {
        const response = await axiosClient.post("/api/chat/title/", {
            ConvoID: data.convoID,
            ConvoTitle: data.convoTitle,
        })

        dispatch({
            type: Chat_TYPES.UPDATE_TITLE,
            payload: data,
        })
    } catch (err) {}
}

export const sendMessage = (data) => async (dispatch) => {
    dispatch({
        type: Chat_TYPES.SEND_MESSAGE,
        payload: { req: data, res: "" },
    })

    try {
        const response = await axiosClient.post("/api/chat/next/", {
            ConvoID: data.ConvoID,
            UserID: data.UserID,
            UserPrompt: data.user_prompt,
        })

        data.status = ""

        dispatch({
            type: Chat_TYPES.SEND_MESSAGE,
            payload: { req: data, res: response },
        })
    } catch (err) {
        data.status = "E"
        dispatch({
            type: Chat_TYPES.SEND_MESSAGE,
            payload: {
                req: data,
                res: "",
            },
        })
    }

    try {
        if (data.chatHistoryLength === 2) {
            const title = await axiosClient.post("/api/chat/model-title/", {
                ConvoID: data.ConvoID,
                UserID: data.UserID,
            })

            var newTitle = {
                convoID: data.ConvoID,
                convoTitle: title,
                lastUpdated: moment(),
            }
            dispatch({
                type: Chat_TYPES.UPDATE_TITLE,
                payload: newTitle,
            })
        }
    } catch (err) {}
}

export const addConversationHistory = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.ADD_CONVEERSATION_HISORY,
            payload: data,
        })
    } catch (err) {}
}

export const isDisableUploadIcon = (data) => async (dispatch) => {
    try {
        if (data === 0) {
            dispatch({
                type: Chat_TYPES.ISDISABLE_UPLOADICON,
                payload: false,
            })
        } else {
            // Get the status of file uploaded or not by passing Conversation ID
            const fileInfo = await axiosClient.post(
                "/api/chat/is-document-uploaded/",
                {
                    ConvoID: data,
                }
            )

            dispatch({
                type: Chat_TYPES.ISDISABLE_UPLOADICON,
                payload: fileInfo,
            })
        }
    } catch (err) {}
}

export const updatePaperClipIcon = (data) => async (dispatch) => {
    try {
        dispatch({
            type: Chat_TYPES.UPDATE_PAPAERCLIPICON,
            payload: data,
        })
    } catch (err) {}
}

export const rejectDocument = (ConvoID) => async (dispatch) => {
    try {
        await axiosClient.post("/api/chat/reject-document/", {
            ConvoID: ConvoID,
        })

        dispatch({
            type: Chat_TYPES.REJECT_DOCUMENT,
            payload: ConvoID,
        })
    } catch (err) {}
}
