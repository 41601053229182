import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import lazyLoadRoutes from '../components/LazyLoadRoutes'; 

const Dashboard = lazyLoadRoutes('Dashboard');
const ToolSupport = lazyLoadRoutes('ToolSupport');

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path='/' element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
                <Route path='/Dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            </Route>
            <Route exact path='/Support' element={<ProtectedRoute><ToolSupport /></ProtectedRoute>}>
            </Route>
        </Routes>
    )
}

export default AppRoutes